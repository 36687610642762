document.onreadystatechange = function()
{
    var el;

    if (document.readyState !== "complete")
    {
        el = document.querySelector("#page");
        if (el !== null)
        {
            el.style.visibility = "hidden";
        }

        el = document.querySelector("#loading-screen");
        if (el !== null)
        {
            el.style.visibility = "visible";
        }
    } 
    else
    {
        el = document.querySelector("#loading-screen");
        if (el !== null)
        {
            el.style.display = "none";
        }

        el = document.querySelector("#page");
        if (el !== null)
        {
            el.style.visibility = "visible";
        }
    }
};